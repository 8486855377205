/**

 Styles are imported on the root style.css because the styles in here were otherwise not activated...

*/

.modal-open {
  app-root {
    main,
    header,
    footer {
      filter: blur(2px);
    }
  }
}

@media (min-width: 768px) {
  .subscription-modal .modal-dialog {
    width: auto !important;
    height: auto !important;
  }

  .subscription-modal .modal-content {
    width: auto !important;
    height: auto !important;
  }
}
