/**

 Styles are imported on the root style.css because the styles in here were otherwise not activated...

*/

.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}

.custom-day.focused {
  background-color: #e6e6e6;
}

.custom-day.range,
.custom-day:hover {
  background-color: rgb(2, 117, 216);
  color: white;
}

.custom-day.faded {
  background-color: rgba(2, 117, 216, 0.5);
}

.ngb-dp-month {
  width: 100%;
}

@media (max-width: 700px) {
  .ngb-dp-day {
    width: auto !important;
    height: auto !important;
  }

  .ngb-dp-month:last-child .ngb-dp-week {
    padding: 0 !important;
  }

  .ngb-dp-weekday {
    width: 13vw !important;
  }

  .custom-day {
    width: 13vw !important;
  }

  .ngb-dp-months {
    width: 100%;
    flex-wrap: wrap;
  }

  .ngb-dp-month {
    pointer-events: none;
    width: 100%;
    flex-flow: column;
    display: flex;
  }
}
