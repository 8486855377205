/**

 Styles are imported on the root style.css because the styles in here were otherwise not activated...

*/

.toast {
  max-width: 100vw;
  flex-basis: auto;
}

app-toasts:empty {
  display: none;
}

app-toasts {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1200;
  bottom: 10px;
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 10px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 10px;
    opacity: 1;
  }
}
