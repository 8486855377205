/* You can add global styles to this file, and also import other style files */

/* Define own variables */
:root {
  --primary: #407900;
  --secondary: #407900;
  --info: #4e6ce4;
  --danger: #d13d3d;
  --navigation-color: var(--primary);
  --navigation-highlight-color: var(--secondary);
  --bs-success-rgb: 0, 147, 86;
}

/* Overwrite the Bootstrap variables */
$primary: #407900;
$secondary: #407900;
$navigation-color: var(--primary);
$info: #4e6ce4;
$danger: #d13d3d;
$bg-danger: #d13d3d;
$success: #009356;


/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Fontawesome CSS file. */
@import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";

/* Import CSS for Animations */
@import "~animate.css/animate.min";

@import "app/page-parts/main-content/subscriptions/subscription/subscription.component.scss";

@import "app/animations/toast/toast.component.scss";

@import "app/page-parts/utils/date-form/date-form.component.scss";

@import "app/pages/content-pages/content-pages.component.scss";

body {
  background-color: var(--bs-white);
}

/* Make the cursor look like a hand */
.cursor-pointer {
  cursor: pointer;
}

/* Bootstrap 5 made buttons unclickable when disabled. This reenables the button so the user can click and see a helpful error message */
.btn {
  pointer-events: auto !important;
}

/* Remove content from DOM */
.hidden {
  display: none;
}

/* Draw red line around invalid forms */
.form-control.ng-invalid.ng-touched {
  border: 1px solid $red;
  border-radius: 0.25em;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.3;
}

.editing label {
  display: none;
}

/* Removes focus boxes */
*:focus {
  outline: transparent;
}

/* Default background image */
picture {
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20baseProfile%3D%22full%22%20width%3D%22100%25%22%20height%3D%22310px%22%3E%3Crect%20opacity%3D%220.1%22%20id%3D%22svg_background%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20x%3D%220%22%20y%3D%220%22%20style%3D%22fill%3A%23407900%3B%22%2F%3E%3Crect%20opacity%3D%220.1%22%20x%3D%220%22%20y%3D%220%22%20width%3D%22100000%22%20height%3D%221000%22%20style%3D%22fill%3A%23407900%3Bstroke-width%3A0%3Bstroke%3A%23000000%3B%22%2F%3E%3C%2Fsvg%3E");
}

/* Margin was removed in Bootstrap 5 but looked nicer in Bootstrap 4 */
label {
  margin-bottom: 0.5em;
}

/* Size definitions for NG Bootstrap's modal dialog box */
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 50vw;
    min-width: 500px;
  }
}

/* Force the shadow box behind a popup to stay in background */
.modal-backdrop {
  z-index: 1000 !important;
}

/* Animations */
.animate__animated.animate__delay-05s {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-delay: var(--animate-delay) / 2;
  animation-delay: var(--animate-delay) / 2;
}

.animate__delay__ordered-0 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.animate__delay__ordered-1 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.animate__delay__ordered-2 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.animate__delay__ordered-3 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.animate__delay__ordered-4 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.animate__delay__ordered-5 {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.animate__delay__ordered-6 {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.animate__delay__ordered-7 {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.animate__delay__ordered-8 {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.animate__delay__ordered-9 {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.animate__delay__ordered-10 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.animate__delay__ordered-11 {
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.animate__delay__ordered-12 {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.animate__delay__ordered-13 {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
}

.animate__delay__ordered-14 {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.animate__delay__ordered-15 {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.animate__delay__ordered-15 {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

.animate__delay__ordered-16 {
  -webkit-animation-delay: 1.7s;
  animation-delay: 1.7s;
}

.animate__delay__ordered-17 {
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
}

.animate__delay__ordered-18 {
  -webkit-animation-delay: 1.9s;
  animation-delay: 1.9s;
}

.animate__delay__ordered-19 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.animate__delay__ordered-20 {
  -webkit-animation-delay: 2.1s;
  animation-delay: 2.1s;
}

.animate__delay__ordered-21 {
  -webkit-animation-delay: 2.2s;
  animation-delay: 2.2s;
}

.animate__delay__ordered-22 {
  -webkit-animation-delay: 2.3s;
  animation-delay: 2.3s;
}

.animate__delay__ordered-23 {
  -webkit-animation-delay: 2.3s;
  animation-delay: 2.3s;
}

.animate__delay__ordered-24 {
  -webkit-animation-delay: 2.4s;
  animation-delay: 2.4s;
}

.animate__delay__ordered-25 {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

.animate__delay__ordered-26 {
  -webkit-animation-delay: 2.6s;
  animation-delay: 2.6s;
}

.animate__delay__ordered-27 {
  -webkit-animation-delay: 2.7s;
  animation-delay: 2.7s;
}

.animate__delay__ordered-28 {
  -webkit-animation-delay: 2.8s;
  animation-delay: 2.8s;
}

.animate__delay__ordered-29 {
  -webkit-animation-delay: 2.9s;
  animation-delay: 2.9s;
}

.animate__delay__ordered-30 {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
